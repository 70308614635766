import React from 'react';

const AppleIcon = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="49"
    height="30"
    viewBox="0 0 49 30"
  >
    <defs>
      <path
        id="apple-icon-a"
        d="M25.9321429,10.3730469 C25.6830357,10.4472656 25.3174107,10.5097656 24.8111607,10.5527344 C24.8553571,8.33789063 26.0125,6.77929688 28.346875,6.25195313 C28.7205357,7.87695313 27.2982143,9.92773438 25.9321429,10.3730469 L25.9321429,10.3730469 Z M30.8058036,13.2128906 C31.0629464,12.8457031 31.4727679,12.4355469 32.0352679,11.9746094 C31.1392857,10.8964844 30.1026786,10.2441406 28.6160714,10.2441406 C27.19375,10.2441406 25.9964286,10.9550781 25.1205357,10.9550781 C24.3772321,10.9550781 23.0352679,10.3261719 21.7254464,10.3261719 C18.9290179,10.3261719 17.4464286,12.9863281 17.4464286,15.7558594 C17.4464286,18.8339844 19.8450893,23.7480469 22.2758929,23.7480469 C23.2321429,23.7480469 24.0919643,23.0449219 25.2290179,23.0449219 C26.40625,23.0449219 27.3504464,23.7167969 28.4553571,23.7167969 C30.3035714,23.7167969 32.0151786,20.6894531 32.5535714,19.0410156 C30.6732143,18.4824219 29.1625,15.5175781 30.8058036,13.2128906 Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width="49" height="30" fill="#000" opacity="0.504" rx="9" />
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#apple-icon-a" />
    </g>
  </svg>
);

export default AppleIcon;
