/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Formik, Form, ErrorMessage } from 'formik';
import download from 'downloadjs';
import Modal from 'react-modal';
import random from 'lodash.random';
import axios from 'axios';
import Yup from 'yup';
import cuid from 'cuid';
import LoadingIcon from '../loading-icon';
import Phrases from './phrases';
import WriteYourOwn from './write-your-own';
import './modal.css';
import {
  Btn,
  UploadForm,
  Wrapper,
  Title,
  PhoneBg,
  TxtInput,
  FileUpload,
  UploadWrap,
  Content,
} from './profile-generator.css';

const S3_URL = 'https://nuagysa052.execute-api.us-east-2.amazonaws.com/prod';

Modal.setAppElement('#___gatsby');

class ProfileGenerator extends Component {
  state = {
    error: undefined,
    progress: undefined,
    file: undefined,
    submitting: undefined,
    showModal: false,
    buildProgress: undefined, // uploading | creating | done | error
    imageUrl: undefined,
    full_name: undefined,
    file: undefined,
  };

  handleCloseModal = () => {
    document.location.reload();
  };

  downloadImg = () => {
    return download(this.state.imageUrl);
  };

  uploadS3 = async file => {
    this.setState({
      showModal: true,
      buildProgress: 'uploading',
    });

    const fileParts = file.name.split('.');
    const filename = `${cuid()}.${file.name.split('.')[fileParts.length - 1]}`;

    const { data: s3Url } = await axios.post(S3_URL, {
      filename,
      filetype: file.type,
    });

    const data = new FormData();
    data.append('file', file);

    const axiosConfig = {
      onUploadProgress: p => {
        this.setState({ progress: Math.round((p.loaded * 100) / p.total) });
      },
      headers: {
        'Content-Type': file.type,
      },
    };

    this.setState({ error: undefined, progress: 0 });

    const upload = await axios.put(s3Url, file, axiosConfig);

    if (upload.status === 200) {
      this.setState({ buildProgress: 'creating' });
      return {
        success: true,
        filename,
      };
    } else {
      this.setState({ buildProgress: 'error' });
    }
  };

  buildImage = async opts => {
    try {
      const url = await axios.post(
        'https://nuagysa052.execute-api.us-east-2.amazonaws.com/prod/build',
        opts
      );

      this.setState({
        buildProgress: 'done',
        imageUrl: url.data,
      });
    } catch (err) {
      this.setState({ buildProgress: 'error' });
    }
  };

  rebuildImage = async message => {
    const { full_name, file } = this.state;

    try {
      const upload = await this.uploadS3(file);

      if (!upload.success) throw 'upload error';

      const url = await axios.post(
        'https://nuagysa052.execute-api.us-east-2.amazonaws.com/prod/build',
        {
          name: full_name.toUpperCase(),
          file: upload.filename,
          phrase: message.toLowerCase(),
        }
      );

      this.setState({
        buildProgress: 'done',
        imageUrl: url.data,
      });
    } catch (err) {
      this.setState({ buildProgress: 'error' });
    }
  };

  render() {
    const { buildProgress, showModal, modalHeight } = this.state;

    return (
      <Wrapper name="profile">
        <Title>Profile Generator</Title>
        <UploadForm>
          <Formik
            initialValues={{
              full_name: '',
              file: '',
            }}
            validationSchema={schema}
            onSubmit={async (values, { setFieldError }) => {
              if (values.file === '') {
                return setFieldError('file', 'You must upload a file');
              }

              const upload = await this.uploadS3(values.file);
              this.setState({
                filename: upload.filename,
                full_name: values.full_name,
                file: values.file,
              });

              if (upload.success) {
                this.buildImage({
                  name: values.full_name.toUpperCase(),
                  file: upload.filename,
                  phrase: Phrases[random(0, Phrases.length - 1)].toLowerCase(),
                });
              }
            }}
            render={({ setFieldValue, values, isSubmitting }) => {
              return (
                <Form>
                  <div>
                    <label htmlFor="file" className="formLine">
                      STEP #1 - UPLOAD PHOTO
                      <UploadWrap>
                        <div
                          className="landingBox"
                          style={{ overflow: 'hidden' }}
                        >
                          {(values.file && values.file.name) ||
                            'Choose Your File'}
                        </div>
                        <FileUpload
                          type="file"
                          name="file"
                          accept="image/png, image/jpeg"
                          id="file"
                          onChange={e => {
                            setFieldValue('file', e.target.files[0]);
                          }}
                        />
                        <ErrorMessage
                          name="file"
                          render={msg => <div className="error">{msg}</div>}
                        />
                      </UploadWrap>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="full_name" className="formLine">
                      Step #2 - Enter Name
                      <TxtInput
                        id="full_name"
                        name="full_name"
                        placeholder="Jim"
                        type="text"
                      />
                      <ErrorMessage
                        name="full_name"
                        render={msg => <div className="error">{msg}</div>}
                      />
                    </label>
                  </div>
                  <div>
                    <Btn type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Generate Photo'}
                    </Btn>
                  </div>
                </Form>
              );
            }}
          />
        </UploadForm>

        <PhoneBg>
          <Img
            style={{ height: '100%' }}
            objectPosition="50% 50%"
            objectFit="contain"
            fluid={this.props.phone.image.childImageSharp.fluid}
          />
        </PhoneBg>
        <Modal
          isOpen={showModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              zIndex: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            content: {
              boxSizing: 'border-box',
              maxWidth: '600px',
              width: '86%',
              height: 'auto',
              minHeight: '400px',
              transition: 'all 120ms ease-out',
              border: '1px solid #e7e7e7',
              borderRadius: '0',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              padding: '0',
              position: 'static',
              top: 'none',
              left: 'none',
              right: 'none',
              bottom: 'none',
              overflow: 'hidden',
            },
          }}
        >
          {buildProgress === 'uploading' ? (
            <Content center>
              <LoadingIcon />
              <div className="message">Uploading... {this.state.progress}%</div>
            </Content>
          ) : buildProgress === 'creating' ? (
            <Content center bgColor="#d5b3bb">
              <LoadingIcon />
              <div className="message" style={{ color: '#d6253d' }}>
                Creating
              </div>
            </Content>
          ) : buildProgress === 'done' ? (
            <Content>
              <CloseIcon closeModal={this.handleCloseModal} />
              <img
                src={this.state.imageUrl}
                alt="With Gusto"
                style={{ width: '100%' }}
              />
              <WriteYourOwn
                rebuildImage={this.rebuildImage}
                downloadImg={this.downloadImg}
              />
            </Content>
          ) : buildProgress === 'error' ? (
            <Content>
              <CloseIcon closeModal={this.handleCloseModal} />
              <div className="message">Something went wrong</div>
              <div className="message">Please try again</div>
            </Content>
          ) : null}
        </Modal>
      </Wrapper>
    );
  }
}

ProfileGenerator.propTypes = {
  phone: PropTypes.object.isRequired,
};

const schema = Yup.object().shape({
  full_name: Yup.string()
    .min(2, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Required'),
});

export default ProfileGenerator;

function CloseIcon({ color = '#999', closeModal }) {
  return (
    <button
      style={{
        position: 'absolute',
        right: '10px',
        top: '10px',
        border: 'none',
        background: 'none',
        outline: 'none',
        padding: 0,
      }}
      onClick={closeModal}
    >
      <svg width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd" transform="translate(-6 -6)">
          <polygon
            fill={color}
            fillRule="nonzero"
            points="24 7.819 22.181 6 15 13.181 7.819 6 6 7.819 13.181 15 6 22.181 7.819 24 15 16.819 22.181 24 24 22.181 16.819 15"
          />
          <polygon points="0 0 30 0 30 30 0 30" />
        </g>
      </svg>
    </button>
  );
}
