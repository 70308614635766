import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  font-family: 'Futura';
  font-weight: bold;
  font-style: normal;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    > span,
    svg {
      margin: 0 10px;
    }
  }

  span {
    color: #d9252a;
    text-transform: uppercase;
    line-height: 2px;
  }

  .mule {
    margin-top: 25px;
    img {
      width: 80px;
    }
  }
`;
