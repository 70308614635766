import styled from 'styled-components';
import { Field } from 'formik';
import Triangle from '../../../static/triangle.svg';

export const Wrapper = styled.div`
  margin-top: 25px;
  padding: 1.4vw 3.4vw;
  background-image: url(${Triangle});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #f45b85;
  position: relative;

  @media (max-width: 700px) {
    margin-top: 0;
    padding: 4vw 3.4vw;
    background-color: #ffa4a4;
  }

  .formLine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:after {
    position: absolute;
    content: '';
  }
`;

export const Title = styled.h2`
  color: #fff;
  margin-bottom: 3.4vw;
  font-family: 'Futura Italic';
  font-size: 5vw;
  text-transform: uppercase;
  text-align: center;
  position: relative;

  @media (min-width: 1200px) {
    font-size: 60px;

    sup {
      font-size: 16.8px;
    }
  }

  @media (max-width: 700px) {
    font-size: 8vw;
    margin-bottom: 20px;

    sup {
      font-size: 2.4vw !important;
    }
  }

  sup {
    vertical-align: super;
    font-size: 1.4vw;
    position: relative;
    top: -1.3vw;
  }
`;

export const Btn = styled.button`
  padding: 15px 20px;
  border-radius: 38px;
  border: none;
  font-family: 'Futura Medium';
  font-weight: 500;
  font-style: normal;
  color: #fff;
  background-image: linear-gradient(270deg, #d9252a 0%, #d02580 100%);
  font-size: 1.1em;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(269deg, #da5097 0%, #e05056 100%);
  }
`;

export const TxtInput = styled(Field)`
  border-radius: 0;
  text-align: center;
  background-image: linear-gradient(270deg, #d9252a 0%, #d02580 100%);
  padding: 10px;
  width: 80%;
  font-size: 0.8em;
  border: none;
  color: #fff;
  font-family: 'Futura';
  margin-top: 15px;

  &::placeholder {
    color: #fff;
  }
`;

export const UploadWrap = styled.div`
  overflow: hidden;
  position: relative;
  width: calc(80% + 20px);

  .landingBox {
    text-align: center;
    background-image: linear-gradient(270deg, #d9252a 0%, #d02580 100%);
    padding: 13px;
    width: calc(100% - 20px);
    font-size: 0.8em;
    border: none;
    color: #fff;
    font-family: 'Futura';
    font-weight: bold;
    font-style: normal;
    margin-top: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
`;

export const FileUpload = styled.input`
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
`;

export const UploadForm = styled.div`
  width: 50%;
  max-width: 450px;

  .formLine {
    font-size: 1.8em;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Futura';
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.25rem;
    position: relative;

    .error {
      font-size: 0.5em;
      margin-top: 10px;
    }
  }

  form > div {
    margin-bottom: 25px;
    text-align: center;
  }

  @media (max-width: 700px) {
    width: 100%;
    margin: 0 auto;

    .formLine {
      font-size: 1.3em;
    }
  }
`;

export const PhoneBg = styled.div`
  display: block;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  align-items: center;
  width: 100%;
  min-height: 400px;
  transition: all 230ms ease-out;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#fff')};

  .message {
    font-family: 'Futura';
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    font-size: 1.7em;
    margin-top: 30px;
  }
`;
