import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Wrapper, Block } from './phones.css';

const Phones = ({ images }) => {
  const [hands, triple] = images;
  return (
    <Wrapper>
      <Block color="#f65984">
        <Img fluid={triple.image.childImageSharp.fluid} />
      </Block>

      <Block color="#ffa1a1">
        <Img fluid={hands.image.childImageSharp.fluid} />
      </Block>
    </Wrapper>
  );
};

Phones.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
    })
  ),
};

export default Phones;
