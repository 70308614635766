import React from 'react';

const PlayIcon = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="49"
    height="30"
    viewBox="0 0 49 30"
  >
    <defs>
      <path
        id="play-icon-a"
        d="M27.7059838,14.1542969 L19.08489,5.50976562 L30.05364,11.8066406 L27.7059838,14.1542969 L27.7059838,14.1542969 Z M16.83489,5.00195312 C16.3270775,5.26757812 15.9872338,5.75195312 15.9872338,6.38085938 L15.9872338,23.6191406 C15.9872338,24.2480469 16.3270775,24.7324219 16.83489,24.9980469 L26.8583275,14.9980469 L16.83489,5.00195312 Z M33.444265,13.8144531 L31.1434838,12.4824219 L28.5770775,15.0019531 L31.1434838,17.5214844 L33.49114,16.1894531 C34.194265,15.6308594 34.194265,14.3730469 33.444265,13.8144531 L33.444265,13.8144531 Z M19.08489,24.4941406 L30.05364,18.1972656 L27.7059838,15.8496094 L19.08489,24.4941406 Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width="49" height="30" fill="#000" opacity="0.502" rx="9" />
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#play-icon-a" />
    </g>
  </svg>
);

export default PlayIcon;
