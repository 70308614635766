import React from 'react';
import { Wrapper } from './footer.css';
import AppleIcon from './apple-icon';
import PlayIcon from './play-icon';
import mule from '../../../static/mule.png';

const Footer = () => (
  <Wrapper>
    <div>
      <span>Coming Soon To</span>
      <PlayIcon />
      <AppleIcon />
    </div>

    <div className="mule">
      <img src={mule} alt="Ocho Mule" />
    </div>
  </Wrapper>
);

export default Footer;
