import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';

import Head from 'components/head';
import Hero from 'components/hero';
import Video from 'components/video';
import ProfileGenerator from 'components/profile-generator';
import Phones from 'components/phones';
import Footer from 'components/footer';

class Index extends Component {
  render() {
    const { data } = this.props;
    console.log(data.homeJson.hero); // eslint-disable-line
    return (
      <Layout>
        <Head />
        <Hero images={data.homeJson.hero} />
        <Video />
        <ProfileGenerator phone={data.homeJson.phones[2]} />
        <Phones images={data.homeJson.phones} />
        <Footer />
      </Layout>
    );
  }
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      phones {
        image {
          childImageSharp {
            fluid(maxHeight: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      hero {
        image {
          childImageSharp {
            fluid(maxHeight: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
