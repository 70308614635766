import React from 'react';
import { Wrapper, Title } from './video.css';
import Youtube from '../../helpers/responsiveYoutube';

const Video = () => (
  <Wrapper>
    <Title>
      DO IT WITH GUSTO <sup>TM</sup>
    </Title>

    <Youtube youtubeId="IXfpyfqGGZ4" />
  </Wrapper>
);

export default Video;
