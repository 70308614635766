/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveYoutube = ({ youtubeId }) => {
  return (
    <div
      id="videos"
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={`https://www.youtube.com/embed/${youtubeId}?modestbranding=1&autohide=1&showinfo=0`}
        frameBorder="0"
      />
    </div>
  );
};

ResponsiveYoutube.propTypes = {
  youtubeId: PropTypes.string.isRequired,
};

export default ResponsiveYoutube;
