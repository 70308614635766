import React from 'react';
import PropTypes from 'prop-types';
import GustoLogo from './gusto-logo';
import { Header, StyledImg, Messaging, HeroMessage, Btn } from './hero.css';
import Img from 'gatsby-image';
import Carousel from 'nuka-carousel';
import shuffle from 'lodash.shuffle';

const Hero = ({ images: defaultImage }) => {
  const images = shuffle(defaultImage);
  return (
    <Header>
      <GustoLogo />

      <Messaging>
        <HeroMessage>
          Find Your
          <br />
          Future Lover
        </HeroMessage>

        <Btn
          to="profile"
          spy={true}
          smooth={true}
          duration={500}
          isDynamic={true}
          href="#"
        >
          Make Your Profile
        </Btn>
      </Messaging>

      <StyledImg>
        <Carousel
          autoplay={true}
          transitionMode="fade"
          autoplayInterval="5000"
          speed="3000"
          wrapAround={true}
          withoutControls={true}
        >
          <Img
            style={{ height: '100%' }}
            fluid={images[0].image.childImageSharp.fluid}
            objectPosition="50% 50%"
          />
          <Img
            style={{ height: '100%' }}
            fluid={images[1].image.childImageSharp.fluid}
            objectPosition="50% 50%"
          />
          <Img
            style={{ height: '100%' }}
            fluid={images[2].image.childImageSharp.fluid}
            objectPosition="50% 50%"
          />
        </Carousel>
      </StyledImg>
    </Header>
  );
};

Hero.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
    })
  ),
};

export default Hero;
