/* eslint-disable */

export default [
  'Netflix and...?',
  "I'm your satellite",
  'aspiring superhero',
  'your valentine',
  'what you wish for',
  'daytime vampire',
  'partner in crime',
  'pull my finger',
  'Do You Love Me?',
  'be mine',
  'looking for you',
  'you buy dinner',
  'press my buttons',
  'apple of your eye',
  'the highlight of your day',
  "heaven's gatekeeper",
  'swipe left on love',
  'wish list: you',
  'steal my heart',
  'dogs love me',
  'your + 1',
  'PTA member',
  'we could be dating',
  'my mom loves me',
  'favorite child',
  'two points for me',
  'center of attention',
  'want hippie love',
  'in it for pizza',
  'hot chocolate?',
  "in-law's favorite",
  "i'll love your dog",
  "i'll love your cat",
  "it's love o'clock",
  'buy me flowers',
  "(I'm Your) Mona Lisa",
  'cat lover',
  'dog lover',
  'favorite neighbor',
  'adventure?',
  'future lover',
  'one of a kind',
  'the eighth wonder',
  'expecting dinner',
  'coffee fiend',
  'looking 4 love',
  'buy me chocolate',
  "i'd pick you first",
  'date me',
  'avid scrapbooker',
  'unexplainably lucky',
  'likes a cold shower',
  'A+ student',
  'big feet',
  'roses are red...',
  'take my hand',
  'lazy, love',
  "doin' it by myself",
  'sinner or saint?',
  'to the rescue',
  'a piece of sunshine',
  'what do you call love?',
  'could be two of us',
  'lucky you',
  'wish you were here',
  'wish I was there',
  'wannabe lover',
  'wannabe bae',
  'your future bae',
  "I'm a page-turner",
  'call me baby',
  'dance with me',
  'arm wrestling champ',
  'amateur chef',
  'talks too much',
  'safe driver',
  "i've got a bus pass",
  'chronic complainer',
  'clumsy af',
  'plant parent',
  'always on it',
  'loses track of time',
  'slight insomniac',
  'lets grab lunch',
  'coffee date?',
  'be my + 1',
  "let's get dinner",
  "I'll make you lunch",
  'guster date?',
  'see u at the show',
  'take me out',
  "let's be spontaneous",
  'au natural',
  'secret rock star',
  'daydreamer',
  'natural leader',
  'always sarcastic',
  'needs attention',
  'artsy fartsy',
  'expecting food',
  'gets hangry',
  'always hangry',
  'great spellr',
  'miss me?',
  'thrill-seeker',
  'adrenaline junkie',
  'too honest',
  'a little naughty',
  "let's get rowdy",
  'wine and dine me',
  'buy me a drink?',
  'silly & sizzling',
  'future CEO',
  'will let you win',
  'great sense of humor',
  'make me laugh',
  'will make you laugh',
  'hold my hand',
  'play with my hair',
  'goal-maker',
  'more charming irl',
  'taller in person',
  'always smells good',
  'greek god in a past life',
  'original human',
  'hopeless romantic',
  'leave me alone',
  'hates parties',
  'happy to cancel plans',
  'wants to go home',
  'sleeps in too late',
  'always snacking',
];
