import styled from 'styled-components';

export const Wrapper = styled.div`
  background-image: linear-gradient(90deg, #d02580 0%, #d9252a 100%);
  text-align: center;
  padding: 1.4vw 3.4vw;
  @media (max-width: 700px) {
    padding: 3.4vw;
  }
`;

export const Title = styled.h1`
  color: #fff;
  margin-bottom: 1.4vw;
  font-family: 'Futura Italic';
  font-size: 5vw;

  @media (min-width: 1200px) {
    font-size: 60px;

    sup {
      font-size: 16.8px;
    }
  }

  @media (max-width: 700px) {
    font-size: 7vw;

    sup {
      font-size: 2.4vw !important;
    }
  }

  sup {
    vertical-align: super;
    font-size: 1.4vw;
    position: relative;
    top: -1.3vw;
  }
`;
