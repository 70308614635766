import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import posed, { PoseGroup } from 'react-pose';

function WriteYourOwn({ downloadImg, rebuildImage }) {
  const [writeCustom, setWriteCustom] = React.useState(false);
  const [customCaption, handleCustomCaption] = React.useState();

  return (
    <PoseGroup>
      {writeCustom ? (
        <Wrapper key="create">
          <NewForm>
            <input
              type="text"
              value={customCaption}
              onChange={e => handleCustomCaption(e.target.value)}
              placeholder="Your custom caption"
              maxLength={15}
            />
            <Btn onClick={() => rebuildImage(customCaption)}>Create</Btn>
          </NewForm>
          <Btn simple onClick={() => setWriteCustom(false)}>
            Cancel
          </Btn>
        </Wrapper>
      ) : (
        <Wrapper key="custom">
          <Btn onClick={downloadImg}>Download Image</Btn>
          <Btn simple onClick={() => setWriteCustom(true)}>
            Want to write your own caption?
          </Btn>
        </Wrapper>
      )}
    </PoseGroup>
  );
}

WriteYourOwn.propTypes = {
  downloadImg: PropTypes.func.isRequired,
  rebuildImage: PropTypes.func.isRequired,
};

export default WriteYourOwn;

const transitionProps = {
  enter: { y: 0, opacity: 1 },
  exit: { y: 10, opacity: 0 },
};

const Wrapper = styled(posed.div(transitionProps))`
  padding: 12px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const Btn = styled.button`
  padding: 8px 16px 7px;
  border-radius: 28px;
  border: none;
  font-family: 'Futura Medium';
  font-weight: 500;
  font-style: normal;
  color: #fff;
  background-image: linear-gradient(270deg, #d9252a 0%, #d02580 100%);
  font-size: 0.85em;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(269deg, #da5097 0%, #e05056 100%);
  }
  ${({ simple }) =>
    simple &&
    css`
      background: none;
      font-size: 0.85em;
      outline: none;
      color: #000;
      &:hover {
        background: none;
        color: #3c4043;
      }
      &:focus {
        color: #d0257c;
      }
    `}
`;

const NewForm = styled.div`
  display: flex;

  > input,
  button {
    margin-left: 3px;
    margin-right: 3px;
  }

  input[type='text'] {
    border: none;
    outline: none;
    border-radius: none;
    border-bottom: 1px solid #d0257e;
    font-family: 'Futura';
    font-weight: bold;
    font-style: normal;
    text-transform: lowercase;
    font-size: 1em;
    min-width: 200px;
  }
`;
