import styled from 'styled-components';
import { Link } from 'react-scroll';

export const Header = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6.9vw);
  min-height: 500px;

  &:after {
    content: '';
    display: block;
    padding-bottom: 66.6666666667%;
  }

  svg {
    position: absolute;
    width: auto;
    left: 25px !important;
    top: 25px;
    z-index: 1;
    margin-right: 0;
    margin-left: 0;
  }

  @media (max-width: 700px) {
    height: 70vh;

    svg {
      width: 50%;
      margin-right: auto;
      margin-left: auto;
      left: 0 !important;
      right: 0;
    }
  }
`;

export const StyledImg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .gatsby-image-wrapper,
  .slider {
    height: 100vh !important;
  }
`;

export const HeroMessage = styled.div`
  display: block;
  font-family: 'Bodoni';
  text-transform: uppercase;
  text-align: center;
  font-size: 9.3vw;
  letter-spacing: 0.1em;

  @media (max-width: 700px) {
    font-size: 11.3vw;
  }

  @media (min-width: 1200px) {
    font-size: 111.6px;
  }
`;

export const Messaging = styled.div`
  color: #fff;
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
`;

export const Btn = styled(Link)`
  background-image: linear-gradient(90deg, #d02580 0%, #d9252a 100%);
  color: #fff;
  font-family: 'Futura Medium';
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.2em;
  border-radius: 18px;
  margin: 15px auto;
  border: none;
  padding: 12px 25px;
  transition: all 110ms ease-out;

  &:hover {
    background-image: linear-gradient(269deg, #da5097 0%, #e05056 100%);
  }
`;
