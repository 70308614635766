import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 25px 0;
  @media (max-width: 700px) {
    margin-top: 0;
  }
`;

export const Block = styled.div`
  width: calc(50% - (25px / 2));
  background-color: ${({ color }) => color};

  @media (max-width: 700px) {
    width: 100%;
    margin-bottom: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
