import React from 'react';
import styled, { keyframes } from 'styled-components';
import Heart from '../../../static/HeartGradient.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
`;

const LoadingIcon = () => (
  <Wrapper>
    <img src={Heart} alt="Loading" />
  </Wrapper>
);

export default LoadingIcon;
